import { Dayjs } from "dayjs";
import React, { createContext, useState } from "react";
import {
  JobJobStatusEnum,
  SettingParamDomainEnum,
} from "../../api/generated/v1.0/app";
import { UserVO } from "../../api/generated/v1.0/open";
import { SettingKeys, Settings, SettingValues } from "../../constants/settings";

export const INITIAL_TEMPS: Pick<
  GlobalState,
  "tempAuth" | "tempEmail" | "tempUserId" | "tempOtp"
> = {
  tempAuth: undefined,
  tempEmail: undefined,
  tempUserId: undefined,
  tempOtp: undefined,
};

const getInitialSettingsValue = (
  domain: SettingParamDomainEnum,
  type: "string" | "boolean" = "string"
): SettingValues => {
  let initVal;
  switch (type) {
    case "boolean":
      initVal = true;
      break;
    default:
      if (domain === "INVOICE") {
        initVal = "3";
      } else {
        initVal = "";
        break;
      }
  }
  return {
    id: 0,
    value: initVal,
    lastModifiedAt: 0,
    valueDataType: type,
    domain,
  };
};

export const INITIAL_SETTINGS: Settings = {
  companyName: getInitialSettingsValue("COMPANY"),
  companyDetails: getInitialSettingsValue("COMPANY"),
  invoiceLimit: getInitialSettingsValue("INVOICE"),
  userManual: getInitialSettingsValue("MANUAL"),
  adminManual: getInitialSettingsValue("MANUAL"),
  importPublication: getInitialSettingsValue("INVOICE", "boolean"),
};

export const INITIAL_GLOBAL: GlobalState = {
  ...INITIAL_TEMPS,
  settings: Object.freeze(INITIAL_SETTINGS),
  hasFetchedRefreshToken: true,
  bulkDownload: {
    state: "NA",
    link: "",
    createdAt: "",
  },
};

export const GlobalContext = createContext<{
  globalState: GlobalState;
  setGlobalState: (nState: Partial<GlobalState>) => void;
}>({
  globalState: INITIAL_GLOBAL,
  setGlobalState: (nState: Partial<GlobalState>) => {},
});

function GlobalProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, setState] = useState<GlobalState>(INITIAL_GLOBAL);

  const setGlobalState = (nState: Partial<GlobalState>) => {
    setState((state) => ({ ...state, ...nState }));
  };

  return (
    <GlobalContext.Provider value={{ globalState: state, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;

// Types
type GlobalState = {
  tempAuth?: {
    userId: string;
    password: string;
  };
  tempUserId?: string;
  tempEmail?: string;
  tempOtp?: number;
  settings: Settings;
  hasFetchedRefreshToken: boolean;
  bulkDownload: BulkDownload;
};

export type BulkDownload = {
  state: JobStatus;
  link: string;
  createdAt: string;
};

export type JobStatus = "NA" | "PROCESSING" | "DONE" | "ERROR";
